import React from 'react'
import { StatusBar } from 'expo-status-bar'
import Navigation from './routes/Navigation'
import { Provider } from 'react-redux'
import { store } from '@/redux/store/store'
import { NavigationContainer } from '@react-navigation/native'

const App = () => {
  // useEffect(() => {
  //   if ('serviceWorker' in navigator) {
  //     window.addEventListener('load', () => {
  //       navigator.serviceWorker
  //         .register('/firebase-messaging-sw.js')
  //         .then((registration) => {
  //           registration.active?.postMessage({
  //             firebaseConfig: {
  //               apiKey: process.env.FIREBASE_API_KEY,
  //               authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  //               projectId: process.env.FIREBASE_PROJECT_ID,
  //               storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  //               messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  //               appId: process.env.FIREBASE_APP_ID,
  //             },
  //           })
  //           console.log('Service Worker registrado con éxito:', registration)
  //         })
  //         .catch((error) => {
  //           console.error('Error al registrar el Service Worker:', error)
  //         })
  //     })
  //   }
  // }, [])

  return (
    <>
      <Provider store={store}>
        <StatusBar style="light" />
        <NavigationContainer>
          <Navigation />
        </NavigationContainer>
      </Provider>
    </>
  )
}

export default App
